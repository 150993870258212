
	ul {
		margin-left: 0;
		padding-left: 0;

		li {
			display: inline-block;
			margin-right: 10px;
		}
	}
