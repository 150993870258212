
	@media only screen and (max-width: 767px) {

		/* stylelint-disable-next-line selector-class-pattern */
		.articleH2 {
			margin-top: 15px;
		}
	}

	.prev-next {
		margin: 20px 0;
		padding: 15px 0;
		border-top: 1px solid #dfdfdf;
		border-bottom: 1px solid #dfdfdf;
		display: flex;
		justify-content: space-between;

		div {
			min-width: 100px;

			&.prev-next-next {
				text-align: right;
			}
		}

		@media only screen and (min-width: 768px) {

			a.pages-link {
				display: none;
			}
		}
	}

	.attachments {
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 1px solid #dfdfdf;
	}

	.axero-entity-formatted-content {

		:deep() {

			iframe {
				border: none;
			}

			// stylelint-disable-next-line selector-class-pattern
			.wistia_embed {
				max-width: 100%;
			}
		}
	}
