
	.tl-widget-content {
		padding: 15px;
	}

	@media (min-width: 768px) {

		#vue-curriculum-component {
			display: flex;

			.tl-content {
				width: 100%;
			}

			.tl-sidebar-right {
				margin-left: 30px;
				min-width: 250px;
			}
		}
	}
