/* stylelint-disable no-invalid-position-at-import-rule */
// Only style vue components
.vue-component {
	@import "isolated/isolated-layout";
	@import "isolated/isolated-typography";
	@import "isolated/isolated-form";

	// Undo styles that Communifire has placed on our components
	// When divorced from Communifire, these styles are not needed
	@import "communifire-reset/communifire-reset-form";

	.no-style {
		@include no-style;
	}
}

body {
	// Styles for Vue and Communifire
	@import "variables";
	@import "reset";
	@import "form";
	@import "typography";
	@import "fonts";
	@import "button";
	@import "space";
}

