
	section {
		max-width: 960px;
		padding: 0 10px;

		a {
			color: rgb(var(--tl-color-font-text));
			text-decoration: underline;
		}

		@media screen and (min-width: 1000px) {
			padding: 0;
		}
	}

	.header {
		position: relative;

		img {
			width: 100%;
		}

		.header-content {

			h1 {
				font-size: 42px;
				margin-bottom: 3px;
			}

			h2 {
				font-weight: 300;
				font-size: 32px;
				margin-top: 0;
				margin-bottom: 20px;
			}
		}

		@media screen and (min-width: 1000px) {
			color: white;

			.header-content {
				position: absolute;
				bottom: 30px;
				left: 30px;
				right: 30px;
				display: flex;
				justify-content: space-between;
				align-items: flex-end;

				h2 {
					margin-bottom: 0;
				}
			}
		}
	}

	.pullout {
		font-weight: 300;
		font-size: 32px;
		max-width: 710px;
		margin: 55px auto 65px auto;
		text-align: center;
	}

	.image-pullout {
		display: flex;
		justify-content: space-between;
		gap: 50px;
		flex-wrap: wrap;

		@media screen and (min-width: 1000px) {

			> * {
				width: calc(50% - 25px);
			}
		}
	}

	.itinerary-ticket {

		.itinerary {
			margin-bottom: 80px;

			h1 {
				font-size: 42px;
				margin-bottom: 40px;
			}

			.days {

				.day {
					display: flex;
					margin-top: 30px;

					.day-date {
						font-weight: 500;
						font-size: 26px;
						width: 165px;
					}

					.day-itinerary {
						font-size: 16px;
						flex: 1;
					}
				}
			}

			.itinerary-overview {
				margin-top: 30px;

				.date {
					font-weight: 500;
					font-size: 26px;
					margin-bottom: 15px;
				}

				.overview {
					font-size: 16px;
				}
			}
		}

		@media screen and (min-width: 1000px) {
			display: flex;
			justify-content: space-between;
			align-items: center;

			> * {
				width: calc(50% - 25px);
			}

			.itinerary {
				margin-bottom: 0;
			}
		}
	}

	.tl-vue-component-calendar-add {
		margin-top: 20px;
	}

	.add-to-calendar-notice {
		margin-top: -5px;
	}

	.notes {
		text-align: left;
		font-size: 16px;
		margin-top: 30px;

		h6 {
			font-weight: bold;
			display: block;
			margin-top: 14px;
			margin-bottom: 7px;
		}

		p {
			margin-bottom: 4px;
		}
	}

	.images {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		img {
			max-width: 180px;
			margin-bottom: 20px;
		}
	}
