
section {
	max-width: 960px;
	padding: 0 10px;

	a {
		color: rgb(var(--tl-color-font-text));
		text-decoration: underline;
	}

	@media screen and (min-width: 1000px) {
		padding: 0;
	}
}

.header {
	position: relative;
	overflow: hidden;

	.header-image {
		min-height: 250px;
		width: auto;
		max-width: 200%;
	}

	.header-content {
		position: absolute;
		bottom: 30px;
		left: 30px;
		right: 30px;
		color: white;

		h1 {
			font-size: 32px;
			font-weight: 400;
			margin: 35px 0 0 0;
			line-height: 1;
		}

		h2 {
			font-size: 57px;
			font-weight: 400;
			margin: 0 0 15px 0;
			line-height: 1;
		}

		h3 {
			font-size: 23px;
			margin: 0;
			line-height: 1;
		}

		.header-icon-dates {
			display: flex;
			align-items: end;

			img {
				margin-right: 15px;
				display: none;
				width: 160px;

				@media screen and (min-width: 700px) {
					display: block;
				}
			}
		}
	}
}

.pullout {
	font-weight: 300;
	font-size: 28px;
	max-width: 700px;
	margin: 55px auto 65px auto;
	text-align: center;

	span {
		white-space: nowrap;
	}
}

.image-pullout {
	display: flex;
	justify-content: center;
	gap: 50px;
	flex-wrap: wrap;

	@media screen and (min-width: 1000px) {
		justify-content: space-between;

		> * {
			width: calc(50% - 25px);
		}
	}
}

.itinerary-ticket {

	.itinerary {
		margin-bottom: 80px;

		h1 {
			font-size: 42px;
			margin-bottom: 40px;
		}

		.days {

			.day {
				display: flex;
				margin-top: 30px;

				.day-date {
					font-weight: 500;
					font-size: 26px;
					width: 165px;
				}

				.day-itinerary {
					font-size: 16px;
					flex: 1;
					margin-top: 5px;
				}
			}
		}

		.itinerary-overview {
			margin-top: 30px;

			.date {
				font-weight: 500;
				font-size: 26px;
				margin-bottom: 15px;
			}

			.overview {
				font-size: 16px;
			}
		}
	}

	@media screen and (min-width: 1000px) {
		display: flex;
		justify-content: space-between;
		align-items: center;

		> * {
			width: calc(50% - 25px);
		}

		.itinerary {
			margin-bottom: 0;
		}
	}
}

.tl-vue-component-calendar-add {
	margin-top: 20px;
}

.add-to-calendar-notice {
	margin-top: -5px;
}

.notes {
	text-align: left;
	font-size: 16px;
	margin-top: 30px;

	h6 {
		font-weight: bold;
		display: block;
		margin-top: 14px;
		margin-bottom: 7px;
	}

	p {
		margin-bottom: 4px;
	}
}

.images {
	margin-top: 50px;

	div {
		display: flex;
		justify-content: center;

		img {
			margin-bottom: 20px;
		}

		&.images-first {

			img {
				width: calc(33% - 10px);
			}
		}

		&.images-second {

			img {
				width: calc(50% - 10px);
			}
		}

		@media screen and (min-width: 1000px) {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}
}

.faqs-tncs {
	margin-top: 40px;

	@media screen and (min-width: 1000px) {
		display: flex;
		justify-content: space-between;

		> * {
			width: calc(50% - 25px);
		}
	}
}
