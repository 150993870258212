
	.vue-tl-card-chooser-component {
		font-size: 12px;
		font-weight: 400;

		h4 {
			margin-bottom: 5px;
		}
	}

	.card-chooser {
		text-align: initial;

		ul {

			li {

				span {
					cursor: pointer;

					&.card-number {
						min-width: 140px;
						display: inline-block;
					}

					&:hover {
						text-decoration: underline;
					}
				}

				&.current {
					font-weight: 600;
				}
			}
		}
	}

	:deep(.cb-card) {

		iframe {
			height: auto;
			min-height: 17px;
		}
	}
