
.vue-tl-modal-component {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgb(0 0 0 / 80%);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: initial;

	.content-inner {
		position: relative;
		width: 600px;
		max-width: 80%;
		background-color: #eeeeee;
		border-top: 4px solid rgb(var(--tl-color-brand-blue));
		padding: 40px;
		border-radius: var(--tl-border-radius);

		.tl-vue-close {
			position: absolute;
			font-size: 28px;
			font-weight: bold;
			color: #555555;
			cursor: pointer;
			top: 4px;
			right: 10px;
			padding: 6px;
			transition: transform 0.3s;

			&:hover {
				transform: scale(1.1);
			}
		}

		.content-header {
			margin-top: -15px;
			margin-bottom: 30px;

			* {
				margin-top: 0;
			}
		}

		.content-footer {
			margin-top: 40px;

			.buttons {
				display: flex;

				button {
					margin: 0 10px 0 0;
				}
			}
		}
	}
}
