
.input-wrapper {

	input {
		margin-bottom: 0;
	}
}

ul {
	margin: 20px 0 10px 0;
	list-style: none;

	li {
		position: relative;
		margin-bottom: 18px;

		a {
			color: rgb(var(--tl-color-font-text));

			.result-title {
				font-weight: 600;
				margin-bottom: 2px;
			}

			.result-body {
				font-size: 0.7rem;
				line-height: 1.3;
			}
		}
	}
}
