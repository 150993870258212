
	// Hide originals
	body.vue-space-add-edit-tag-list {

		#popular-pane,
		.tag-editor .tag-watermark,
		.tag-editor input.tag-input,
		.ax-add-tags-taggroup {
			display: none;
		}

		.tag-editor {
			min-height: 34px;
			cursor: initial;
		}

		label[id*="AddTagsControl_TagLabel"] {
			cursor: default;
		}
	}
