
	li {
		z-index: 1;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 10px;
		height: 70px;
		margin: auto 0;
		border: 1px solid rgb(var(--tl-color-brand-grey-darkest));
		border-right: none;
		border-top-left-radius: var(--tl-border-radius);
		border-bottom-left-radius: var(--tl-border-radius);
		background-color: white;

		&::after {
			content: '';
			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			border-right: 5px solid rgb(var(--tl-color-brand-grey-darkest));
			position: absolute;
			top: 50%;
			left: 2px;
			margin-top: -5px;
			transition: transform 0.3s;
		}

		&.is-sidebar-collapsed {
			
			&::after {
				transform: rotate(180deg);
				left: 3px;
			}
		}

		.sidebar-collapsed & {
			right: -10px;
		}
	}
