
#pages {

	.lesson-heading {
		position: absolute;
		right: 0;
		top: 4px;
		font-weight: 400;
	}

	> .wiki-title {
		font-weight: bold;
		padding-left: 0;
	}

	> ul {
		max-height: initial;
	}
}

ul {
	margin: 0 0 0 14px;

	li {
		list-style: none;
	}
}

.wiki-tree-item {

	.wiki-title {
		position: relative;
		padding-left: 20px;

		i {
			position: absolute;
			left: -5px;
			cursor: pointer;
			margin-right: 2px;
			padding: 6px 10px;

			&::before {
				transition: transform 0.2s;
				color: rgb(var(--tl-color-brand-blue));
			}
		}

		a {
			position: relative;
			cursor: pointer;
			display: block;
			color: rgb(var(--tl-color-font-text));
			padding: 3px 0 4px 0;

			.lesson-status {
				position: absolute;
				right: 0;
				top: 10px;
				width: 28px;
				height: 8px;
				background-color: #dfdfdf;
				border-radius: 4px;

				&::after {
					content: "";
					background-color: #54ab4a;
					border-radius: 4px;
					width: 0%;
					height: 100%;
					display: block;
				}

				&.lesson-status-passed {

					&::after {
						width: 100%;
					}
				}

				&.lesson-status-failed,
				&.lesson-status-graded,
				&.lesson-status-ungraded {

					&::after {
						width: 75%;
					}
				}
			}

			&:hover {
				color: rgb(var(--tl-color-font-text));
			}
		}
	}

	ul {
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.2s;
	}

	&.open {

		> ul {
			max-height: 1000px;
		}

		> .wiki-title i::before {
			transform: rotate(90deg);
		}
	}

	&.current,
	&.current-child {

		> .wiki-title {
			font-weight: bold;
		}
	}
}
