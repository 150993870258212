
.graph-container {
	position: relative;
	margin-bottom: -40px;

	.belts {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 65px;
		padding: 6px;
		background-color: rgb(var(--tl-color-brand-grey));
		margin: 0 64px;

		.belts-inner {
			position: relative;

			label {
				position: absolute;
				top: -6px;
				left: -62px;
				background-color: rgb(var(--tl-color-brand-grey));
				margin: 0;
				padding: 1px 16px 1px 20px;
			}

			ul {
				margin: 0;
				list-style: none;
				display: flex;
				text-align: center;

				li {
					height: 10px;
				}
			}
		}
	}
}

.graph-year-labels {
	margin: 4px 70px 0 70px;
	display: flex;
	text-align: center;

	div {
		border: 1px solid grey;
		border-top-width: 0;
		position: relative;
		margin: 0 1px 20px 1px;

		span {
			position: relative;
			top: 14px;
			height: 10px;
			display: block;
		}

		&.cutoff-start {
			border-left-width: 0;
		}

		&.cutoff-end {
			border-right-width: 0;
		}
	}
}

.toggles {
	display: flex;
	list-style: none;
	margin: 40px 70px 0 70px;
	font-size: 14px;

	li {
		border: 1px solid rgb(var(--tl-color-font-text));
		padding: 4px 14px;
		border-radius: 10px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		user-select: none;

		&[data-activty-style="0"] {
			min-width: 70px;
			margin-right: 5px;

			&[data-activty-id="-1"] {
				margin-right: 20px;
				margin-left: auto;
				position: relative;

				&::after {
					content: "·";
					position: absolute;
					right: -18px;
					top: 1px;
					color: rgb(var(--tl-color-font-text));
					font-size: 34px;
				}

				@media screen and (max-width: 1350px) {
					color: transparent;

					&::before {
						content: "Acc";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 99;
						color: white;
						text-align: center;
					}
				}
			}
		}

		&[data-activty-style="1"] {
			min-width: 85px;
			margin-left: 5px;
		}

		&.active {
			color: white;
			border-color: transparent;

			&[data-activty-style="0"] {
				background-color: rgb(var(--tl-color-belt-blue));

				&[data-activty-id="2"] {
					background-color: #24b4e8;
				}

				&[data-activty-id="-1"] {
					background-color: rgb(162 139 62);
				}
			}

			&[data-activty-style="1"] {

				&[data-activty-id="1"] {
					background-color: #2f8a35;
				}

				&[data-activty-id="2"] {
					background-color: #41ad49;
				}

				&[data-activty-id="3"] {
					background-color: #36cc41;
				}
			}
		}
	}
}
