
	@import "@/scss/utilities";

	.avatar {
		box-sizing: unset;

		.image {
			@include aspect-ratio-box;
		}

		.thought-belt-status {
			float: none;
		}

		img {
			width: 100%;
		}
	}
