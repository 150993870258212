
.thought-admin-tools-section-broadcasts {

	.broadcast-delete-button {

		&:hover {
			text-decoration: underline;
			color: red;
			cursor: pointer;
		}
	}
}
