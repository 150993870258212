
	.vue-tl-price-component {
		margin: 20px 0;
		
		h2 {
			position: relative;

			.gst-star {
				font-size: 14px;
				position: relative;
				top: -19px;
				left: 0;
			}
		}
	}
