
.vue-tl-signature-component {

	.signature {
		margin-bottom: 40px;
		display: flex;
		flex-wrap: wrap;

		.signature-container {
			display: inline-block;
			border: 2px solid rgb(var(--tl-color-font-text));
			border-radius: var(--tl-border-radius);
			overflow: hidden;
		}

		.signature-buttons {
			user-select: none;
			display: flex;
			flex-direction: column;
			margin-left: 10px;

			div {
				cursor: pointer;
				width: 30px;
				height: 30px;
				font-size: 28px;
				line-height: 1;
				font-weight: bold;
				border: 1px solid rgb(var(--tl-color-font-text));
				border-radius: var(--tl-border-radius);
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-bottom: 4px;
			}
		}

		.signed {
			width: 100%;
		}
	}
}
