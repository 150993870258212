
.vue-tl-tlbs-commitment-agreement-component {

	:deep() {
		padding: 0 10px;

		@media screen and (min-width: 1000px) {
			padding: 0;
		}

		section.agreement {
		
			.joined {
				padding: 40px;
				background-color: rgb(var(--tl-color-brand-blue));
				border-radius: var(--tl-border-radius);
				color: white;
			}
		
			.immersion-choice {
				margin-bottom: 40px;
		
				label {
					display: block !important;
					margin-bottom: 15px;
				}
		
				select {
					font-size: 18px;
					min-width: 240px;
					height: auto;
				}
			}

			.your-details {

				.name-details {
					margin-bottom: 15px;
				}

				margin-bottom: 40px;
			}
			

			.country-choice {
				margin-bottom: 40px;

				label {
					display: block !important;
					margin-bottom: 15px;
				}
		
				select {
					font-size: 18px;
					height: auto;
					margin-bottom: 0;
				}
			}
		
			.accepted-terms {
				margin-bottom: 40px;
			}
		
			.signature {
				margin-bottom: 40px;
				display: flex;
				flex-wrap: wrap;
		
				.signature-container {
					display: inline-block;
					border: 2px solid rgb(var(--tl-color-font-text));
					border-radius: var(--tl-border-radius);
					overflow: hidden;
				}
		
				.signature-buttons {
					user-select: none;
					display: flex;
					flex-direction: column;
					margin-left: 10px;
		
					div {
						cursor: pointer;
						width: 30px;
						height: 30px;
						font-size: 28px;
						line-height: 1;
						font-weight: bold;
						border: 1px solid rgb(var(--tl-color-font-text));
						border-radius: var(--tl-border-radius);
						margin-bottom: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						padding-bottom: 4px;
					}
				}
		
				.signed {
					width: 100%;
				}
			}
		}
	}
}
