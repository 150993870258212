
.vue-tl-plan-purchase-component {
	position: relative;
	min-height: 95px;
}

button {
	width: clamp(100px, 100%, 300px);
}

.plans {
	display: flex;
	justify-content: center;

	ul {
		min-width: 250px;
		margin: 20px 0;
		list-style: none;
		background-color: white;
		border-radius: 4px;
		font-size: 14px;

		li {
			position: relative;
			display: flex;
			align-items: center;
			padding: 12px 20px 13px 4px;
			cursor: pointer;

			div {
				position: absolute;
				left: 12px;
				top: 13px;
				border-radius: 50%;
				border: 2px solid #eeeeee;
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			span {
				padding-left: 40px;
			}

			&:first-child {
				border-top-left-radius: 4px;
				border-top-right-radius: 4px;
			}

			&:last-child {
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
			}

			&:hover {
				background-color: rgb(var(--tl-color-brand-blue) / 20%);
			}

			&.plan-selected {
				background-color: rgb(var(--tl-color-brand-blue));
				color: white;

				div {
					border-color: white;

					&::after {
						content: "";
						width: 12px;
						height: 12px;
						background-color: white;
						border-radius: 50%;
					}
				}
			}
		}
	}
}

.current-card {
	margin-bottom: 15px;
	font-size: 12px;
	font-weight: 400;
	
	a {
		cursor: pointer;
	}
}
