
$left-margin: 12px;

.x-to-y-tooltip {
	display: inline-block;
}

.belt-tooltip-title {
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 15px;
}

.belt-container {
	position: relative;
	background-color: #f8f8f8;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	padding: 8px 8px 8px $left-margin;
	overflow: hidden;

	.fade-enter-active,
	.fade-leave-active {
		transition: all 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
		transform: translateX(30px);
	}

	.belt {
		display: flex;
		justify-content: space-between;
		min-height: 67px;

		p {
			color: rgb(var(--tl-color-font-text));
			font-size: 14px;
			width: calc(100% - 60px);

			:deep(span) {
				font-weight: bold;
			}
		}

		.avatar {
			width: 60px;

			img {
				width: 100%;
			}

			.thought-belt-status {
				height: 5px;
				margin-bottom: 0;

				.thought-belt-colour {

					&.white,
					&.subwhite {
						background-color: #ffffff;
					}
				}
			}
		}
	}
}

.belt-picker {
	position: absolute;
	bottom: 0;
	left: $left-margin;

	button {
		padding: 0;
		margin-right: 4px;
		border: none;
		background-color: transparent;
		line-height: 0;

		span {
			margin: 15px 0 10px 0;
			display: inline-block;
			background-color: #e8e8e9;
			height: 5px;
			width: 30px;
		}

		&.active {

			span {
				background-color: #c4c4c4;
			}
		}

		&:hover {

			span {
				background-color: #9e9d9d;
			}
		}
	}
}
