& {

	.ax-attachment-upload-section .qq-upload-button,
	.btn {
		text-transform: uppercase;
		letter-spacing: 3px;
		font-size: 0.9rem;
		font-weight: 600;
		font-style: normal;
		color: #ffffff !important;
		background-color: rgb(var(--tl-color-brand-blue));
		background-image: none;
		border-color: rgb(var(--tl-color-brand-blue));
		text-shadow: none;
		display: inline-block;
		line-height: 1.1;
		padding: 1em 2.5em;
		border: none;
		border-radius: 4px;
		text-align: center;
		cursor: pointer;
		outline: none;
		appearance: none;
		margin: 10px 0;
		box-shadow: none;
		transition: background-color 0.15s;

		.icon-plus-sign {

			&::before {
				transition: transform 0.15s;
			}
		}

		&[disabled] {
			background-color: rgb(var(--tl-color-brand-blue) / 70%);
			border-color: rgb(var(--tl-color-brand-blue) / 70%);
			opacity: 1;
			cursor: default;
		}

		&:hover:not([disabled]) {
			color: #ffffff !important;
			background-color: #2A377E;

			.icon-plus-sign {

				&::before {
					transform: scale(1.3);
				}
			}
		}

		&:focus {
			color: #ffffff !important;
			background-color: rgb(var(--tl-color-brand-blue));
			outline: none;
		}

		&.btn-mini {
			font-size: 8px;
		}

		&.btn-small {
			padding: 0.5em 1.5em;
			margin: 0;
			font-size: 0.9rem;
		}

		&.btn-secondary {
			color: rgb(var(--tl-color-brand-blue)) !important;
			background-color: rgb(var(--tl-color-brand-grey-darker));

			&[disabled] {
				background-color: rgb(var(--tl-color-brand-grey-darker) / 70%);
				border-color: rgb(var(--tl-color-brand-grey-darker) / 70%);
				opacity: 1;
				cursor: default;
			}
	
			&:hover:not([disabled]) {
				color: rgb(var(--tl-color-brand-blue)) !important;
				background-color: rgb(var(--tl-color-brand-grey-darkest));
			}
		}

		&.btn-tertiary {
			color: rgb(var(--tl-color-error)) !important;
			background-color: rgb(var(--tl-color-brand-grey-darker));

			&[disabled] {
				background-color: rgb(var(--tl-color-brand-grey-darker) / 70%);
				border-color: rgb(var(--tl-color-brand-grey-darker) / 70%);
				opacity: 1;
				cursor: default;
			}
	
			&:hover:not([disabled]) {
				color: rgb(var(--tl-color-error)) !important;
				background-color: rgb(var(--tl-color-brand-grey-darkest));
			}
		}
	}

	form {

		.btn-active, .btn.disabled.btn-active, .btn[disabled].btn-active {
			background-image: url('@/images/loading.gif');
			background-color: rgb(var(--tl-color-brand-blue) / 70%);
			background-size: 25px;
			background-position: center;
			background-repeat: no-repeat;
			color: transparent !important;
			animation: none;
		}
	}
}