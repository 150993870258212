

section.dates {

	.col-3 {
		text-align: right;
		padding-right: 40px;
		font-weight: bold;
	}
}

ul {
	list-style: none;
	margin: 0;

	li {
		margin-bottom: 1rem;

	}
}
