
section.vue-tl-hero-image-component {
	position: relative;
	background-image: url("@/images/commitment-agreement/hero.jpg");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 350px;
		
	.hero-content {
		position: absolute;
		bottom: 30px;
		left: 30px;
		right: 30px;
		color: white;
			
		h3 {
			font-weight: 400;
			margin: 0;
			line-height: 1;
		}
		
		h1 {
			margin: 0;
		}
	}
}
