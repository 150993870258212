textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	box-shadow: none;
}

label {
	display: inline-block;
}

select {
	height: auto;
	padding: 5px 6px;
}

label.input-checkbox {
	font-size: 18px;
	font-weight: 400;
	margin: 4px 0 0 0;
	display: flex !important;
	align-items: center;

	input {
		width: 28px;
		height: 28px;
		margin: 0 15px 0 0;
	}
}