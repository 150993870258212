
table {
	table-layout: fixed;

	thead:first-of-type {

		tr {

			th {

				&:nth-of-type(1) {
					width: 25%;
				}

				&:nth-of-type(2) {
					width: 45%;
				}

				&:nth-of-type(3) {
					width: 20%;
				}

				&:nth-of-type(4) {
					width: 5%;
				}
			}
		}
	}

	.announcements-list {

		tr {
			word-break: break-all;
		}
	}

	.editor {

		.editor-buttons {
			margin: 10px 0;

			button {
				margin-right: 5px;
				min-width: 25px;

				&.is-active {
					background-color: black;
					color: white;
				}
			}
		}

		:deep(.prose-mirror) {
			border: 1px solid grey;
			border-radius: 4px;
			min-height: 80px;
			padding: 8px 12px;
		}
	}

	.date {

		/* stylelint-disable-next-line selector-class-pattern */
		:deep(.dp__main) {

			input[readonly] {
				cursor: pointer;
			}
		}
	}
}
