& {
	// Colors
	// Can't apply new CSS color functions yet, so have use RGB values in
	// variable definitions and then call `rgb` on every usage to get dynamic
	// opactiy.
	--tl-color-brand-blue: 24 29 70;
	
	// Increased HSLA lightness to allow the blue to come through better in small amounts
	// IE small copy, small icons
	--tl-color-brand-blue-small: 49 59 142;
	
	// Colors - Greys
	--tl-color-brand-grey-darkest: 185 185 185;
	--tl-color-brand-grey-darker: 197 197 197;
	--tl-color-brand-grey: 226 226 226;
	--tl-color-brand-grey-lighter: 232 232 232;
	--tl-color-brand-grey-lightest: 240 240 240;
	
	// Colors - Fonts
	--tl-color-font-text: 85 85 85;
	--tl-color-font-link: var(--tl-color-brand-blue-small);
	
	// Colors - Space generals
	--tl-color-space-default: var(--tl-color-brand-blue);
	--tl-color-space-current: var(--tl-color-space-default);
	--tl-color-space-announcement: var(--tl-color-space-current);
	
	// Colors - Space specifics
	--tl-color-space-foundation-program: 226 105 156;
	--tl-color-space-business-school: 255 164 54;
	--tl-color-space-bbp: 115 190 183;
		
	// Colors - Belts
	--tl-color-belt-green: 65 173 73;
	--tl-color-belt-blue: 0 128 198;
	
	// Colors - Deliverables
	--tl-color-deliverables-pinksheet: var(--tl-color-space-foundation-program); 
	--tl-color-deliverables-greensheet: 106 174 54;
	
	// Colors - Activities
	--tl-color-activity-sale: 0 128 198;
	--tl-color-activity-delivery: 36 180 232;
	--tl-color-activity-approach: 47 138 53;
	--tl-color-activity-meeting: 65 173 73;
	--tl-color-activity-proposal: 54 204 65;

	// Colors - Streaks
	--tl-color-streak-green: var(--tl-color-deliverables-greensheet);
	--tl-color-streak-orange: 240 135 0;
	--tl-color-streak-red: 234 29 31;
	
	// Colors - Misc
	--tl-color-misc-gold: 162 139 62;
		
	// Typography
	
	// Validation
	--tl-color-error: 233 50 45;
	
	// Layout
	--tl-border-radius: 4px;
}