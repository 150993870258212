
.tl-tooltip-container {
	position: relative;

	.tl-tooltip-trigger {
		cursor: pointer;
	}

	.tl-tooltip {
		display: block;
		z-index: 99;
		position: absolute;
		left: 50%;
		top: 30px;
		margin-left: -150px; // can't use transform because ModalComponent's inside don't work
		width: 300px;
		background-color: #383838;
		border-radius: 8px;
		color: #dddddd;
		line-height: 1.2;
		text-align: center;
		padding: 30px;

		&::before {
			content: "";
			position: absolute;
			top: -12px;
			left: 50%;
			margin-left: -20px;
			width: 0;
			height: 0;
			border-left: 20px solid transparent;
			border-right: 20px solid transparent;
			border-bottom: 20px solid #383838;
		}
	}
}
