
@import "@/../node_modules/vue3-toggle-button/dist/style.css";

.info-lacking {
	position: relative;
	max-width: 800px;
	font-size: 20px;
	font-weight: bold;
	margin: 10px auto 30px auto;
	padding: 15px 40px 15px 40px;
	border: 1px solid #cccccc;
	border-radius: 4px;

	p {
		margin-bottom: 0;
	}

	a {
		color: rgb(var(--tl-color-brand-blue));
	}

	span {
		font-size: var(--tl-typography-font-size);
		font-weight: 400;
	}

	.vue-close {
		position: absolute;
		top: 1px;
		right: 7px;
	}
}

.filters {
	margin: 10px 0;

	input {
		padding: 4px 6px;
		margin-right: 15px;
	}

	select {
		margin-right: 10px;
	}

	button {
		margin-top: -3px;
		margin-bottom: 8px;
	}
}

#g-map {
	margin-top: 20px;
	margin-bottom: 20px;

	:deep() {

		.marker-label {
			position: relative;
			top: -6px;
		}
	}
}

.people-stats {
	margin: 0 0 20px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	fieldset {
		margin-right: auto;
		margin-left: 25px;

		label {
			margin-bottom: 0;
			font-weight: 400;
			display: flex !important;
			justify-content: center;
			align-items: center;

			.toggle-slider {
				margin-left: 8px;
			}
		}
	}

	.sort {
		margin-right: 4px;
		font-weight: bold;
		font-size: 16px;
	}
}

.people {
	--grid-layout-gap: 10px;
	--grid-column-count: 6;
	--grid-item-min-width: 170px;
	--gap-count: calc(var(--grid-column-count) - 1);
	--total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
	--grid-item-max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item-min-width), var(--grid-item-max-width)), 1fr));
	grid-gap: var(--grid-layout-gap);

	.space-person {
		cursor: pointer;

		:deep() {

			.thought-belt-status {
				height: 6px;
			}
		}

		@media screen and (min-width: 1200px) {
			flex: 15%;
		}
	}
}

#user-card-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(0 0 0 / 50%);
	z-index: 2;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	.user-card {
		margin-top: 40px;
		position: relative;
		cursor: default;
		width: 300px;
		max-width: 100%;
		background-color: #ffffff;
		border-radius: 5px;
		padding: 20px;

		.user-card-content {

			:deep() {

				.thought-belt-status {
					height: 15px;
				}
			}

			.user-card-header {
				margin: 15px 0;
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				h4 {
					margin: 0;
				}
			}
		}

		.user-card-close {
			padding: 6px;
			font-size: 22px;
			font-weight: bold;
			line-height: 12px;
			position: absolute;
			top: -2px;
			right: -1px;
			cursor: pointer;
		}
	}
}

