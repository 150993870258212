

	.agreement {
		position: relative;
	}

	.cb-card {
		padding: 15px;
		max-width: 500px;
		margin: 30px auto 20px auto;
		border: 1px solid rgb(118 118 118);
		border-radius: var(--tl-border-radius);
	}

	.joined {
		padding: 40px;
		background-color: rgb(var(--tl-color-brand-blue));
		border-radius: var(--tl-border-radius);
		color: white;
	}
