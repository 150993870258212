@import "../utilities";

& {

	.btn-a {
		@include no-style;

		&:hover {
			text-decoration: underline;
		}
	}

	.input-wrapper {
		position: relative;

		label {
			margin-bottom: 3px;
		}

		input {
			width: 100%;
		}

		.clear {
			@include no-style;

			position: absolute;
			padding: 8px 12px;
			right: 3px;
			top: 1px;

			&:hover {
				font-weight: 600;
			}
		}
	}

	fieldset {

		legend {
			font-size: var(--tl-typography-font-size);
			line-height: var(--tl-typography-line-height);
			margin-bottom: 4px;
			border: none;
		}

		div {

			label {
				display: block !important;
				font-weight: 400;
				margin-bottom: 0;
			}
		}
	}

	/* stylelint-disable-next-line selector-not-notation */
	input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
		padding: 7px 10px;
		font-size: 18px;
		height: auto;
	}
}
