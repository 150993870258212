
	.axero-widget {
		background-color: #0080c6;
		border: none;
		position: relative;
		overflow: hidden;
		padding: 20px 15px;

		.axero-widget-background {
			position: absolute;
			bottom: -50px;
			right: -50px;
			width: 200px;
			height: auto;
			filter: opacity(0.2);
		}

		a {
			display: block;
			color: #ffffff;
			position: relative;

			.axero-widget-header {
				border-bottom: none;
				background: none;
				padding: 0;
				margin-bottom: 15px;

				h2 {
					line-height: 1.1;
					font-size: 24px;
				}
			}

			.axero-widget-content {
				padding: 0;

				p {
					margin: 0;
					font-size: 14px;
					letter-spacing: 0.04em;
					font-weight: 500;
				}

				h2 {
					margin: -2px 0;
				}
			}

			&:hover {
				text-decoration: none;
			}
		}
	}
