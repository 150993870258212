#axero-space-nav {

	.axero-space-navigation {

		ul {

			.axero-space-navigation-item {
				border-radius: 2px;
				margin-bottom: 4px;
				padding-left: 8px;

				.axero-space-navigation-item-icon {
					color: rgb(var(--tl-color-space-current));

					&.thought-space-navigation-item-svg {
						max-height: 20px;

						svg {
							max-height: 20px;

							path {
								fill: rgb(var(--tl-color-space-current));
							}
						}

						&.thought-space-navigation-item-notice-board {

							svg {
								max-height: 17px;
								margin-top: 3px;
							}
						}
					}
				}

				.axero-space-navigation-item-name {
					margin-left: 27px;
				}

				&.ax-space-menu-activitystream {

					.axero-space-navigation-item-icon {
						position: relative;
						top: 1px;
					}
				}

				&:hover {
					background-color: rgb(var(--tl-color-space-current) / 20%);
				}

				&.active {
					background-color: rgb(var(--tl-color-space-current));
					color: white;
					font-weight: bold;

					i, a {
						color: white;
					}

					svg {

						path {
							fill: white !important;
						}
					}
				}
			}
		}
	}
}

.tl-mobile-menu {

	ul {

		li {

			&.active {

				a {
					color: rgb(var(--tl-color-space-current));

					svg {

						path {
							fill: rgb(var(--tl-color-space-current));
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 980px) {

	.ax-mobile-myaccount-menu .icon-bar {
		background-color: rgb(var(--tl-color-space-current)) !important;
	}

	.axero-space-navigation .axero-space-navigation-item .axero-space-navigation-item-icon {
		color: rgb(var(--tl-color-space-current)) !important;
	}
}

@media screen and (max-width: 767px) {

	.axero-space-navigation {

		.axero-space-navigation-item {

			&:hover,
			&:active,
			&.active {
				background-color: rgb(var(--tl-color-space-current) / 20%) !important;
			}
		}
	}
}

.thought-menu-item {
	margin-right: 10px;
	padding-right: 10px;

	a {
		color: white !important;
		font-weight: 400 !important;
	}

	&.thought-active {

		a {
			font-weight: bold !important;
			color: rgb(var(--tl-color-space-item)) !important;
		}
	}
}

// Activity feed "posted in: [space]" text
.axero-wall-entry-post > a[href^="/spaces/"] {
	display: inline-block;
	padding: 0 5px;
	margin: 0 2px;
	color: rgb(var(--tl-color-space-item));
	border: 1px solid #efefef;
	border-radius: 3px;

	&:hover {
		border-color: rgb(var(--tl-color-space-item) / 50%);
		text-decoration: none;
	}
}

// Search results "posted in: [space]" text
.axero-article-list-userinfo a {
	color: rgb(var(--tl-color-space-item));
}

.axero-space-header-title-name a {
	color: rgb(var(--tl-color-space-current));
}

.axero-search-results-container-right {

	.axero-search-filter-item-icon span {
		color: rgb(var(--tl-color-space-current));
	}
}

.articlelist h4 a {
	color: rgb(var(--tl-color-space-current));
}

.axero-wall-entity-icon-container i {
	color: rgb(var(--tl-color-space-current));
}

/* Restore grey links */
.axero-space-page-options li a,
.axero-wiki-background ul.axero-entity-option-bar a {

	&:link,
	&:visited,
	&:active,
	&:hover {
		color: #afafaf !important;
	}
}

/* Restore mid-grey links */
.ax-diamond-hover-box-dropdown a {

	&:link,
	&:visited,
	&:active,
	&:hover {
		color: #555555 !important;
	}
}

/* Restore dark-grey links */
.axero-search-filter-item-name a,
.axero-space-navigation-item-name a {

	&:link,
	&:visited,
	&:active,
	&:hover {
		color: inherit !important;
	}
}

/* Restore blue links */
.axero-ticker-item-content a {

	&:link,
	&:visited,
	&:active,
	&:hover {
		color: rgb(var(--tl-color-font-link)) !important;
	}
}

// Hide bottom border of search app since we've moved it to the bottom of the list anyway
.axero-space-navigation-item.ax-space-menu-search {
	border-bottom: none;
}

@media screen and (min-width: 768px) {

	#axero-space-nav {

		.axero-space-navigation {
			border: none;
		}
	}

	.axero-space-page-options {
		border: none;

		li[id*="LeaveSpace"] {
			display: none;
		}
	}
}