
.vue-tl-sidebar-component {
	padding: 20px;
	margin: 20px 0;
	background-color: rgb(var(--tl-color-brand-grey));
	border-radius: var(--tl-border-radius);
	border-top: 4px solid rgb(var(--tl-color-brand-blue));
	text-align: center;

	@media screen and (min-width: 768px) {
		padding: 40px;
	}
}
