& {
	--tl-typography-font-size: 16px;
	--tl-typography-font-size-small: 0.75rem; // 12px
	--tl-typography-p-margin-bottom: 1.0em;


	p {
		margin-top: 0;
		margin-bottom: var(--tl-typography-p-margin-bottom);
		max-width: 1000px;
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-weight: 600;
		margin-top: 0;
		margin-bottom: 0.6em;
	}

	* + {

		h1,
		h2,
		h3,
		h4,
		h5 {
			margin-top: 0.8em;
		}
	}

	h1 {
		font-size: 2.75rem; // 44px
		line-height: 1;
	}

	h2 {
		font-size: 2rem; // 34px
		line-height: 1;
	}
	
	h3 {
		font-size: 1.5rem // 24px
	}

	h4 {
		font-size: 1.125rem; // 18px
		font-weight: 300;
	}

	h5 {
		font-size: 1rem;
		letter-spacing: 0.05em;
		line-height: 1.2em;
		text-transform: uppercase;
	}

	small {
		font-size: var(--tl-typography-font-size-small);
	}

	mark {
		background-color: rgb(var(--tl-color-brand-blue) / 40%);
	}

	ul,
	ol {
		margin: 0 0 var(--tl-typography-p-margin-bottom) 1rem;

		li {
			line-height: 1.35em;
		}
	}

	hr {
		margin: 40px 0;
		border: none;
		height: 1px;
		background-color: #dddddd;
	}

	@media screen and (min-width: 1200px) {

		h1 {
			line-height: 1.2;
			font-size: 3.75rem; // 60px
		}
	
		h2 {
			line-height: 1.2;
			font-size: 2.75rem; // 44px
		}
		
		h3 {
			font-size: 2.125rem // 34px
		}
	
		h4 {
			font-size: 1.5rem; // 24px
		}
	
		h5 {
			font-size: 1.125rem; // 18px
		}
	}
}
