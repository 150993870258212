
.tl-widget-leaderboard{

	.tl-streaks {
		margin: 0;
		display: flex;
		flex-direction: column;

		li {
			list-style: none;
			margin-bottom: 5px;
		}
	}

	.divider {
		margin: 10px 0 15px;
		display: flex;
		height: 3px;
		position: relative;
		background:rgb(var(--tl-color-brand-grey-darkest));

		.divider-left {
			flex-grow: 1;

			&::after
			{
				content: "";
				position: absolute;
				z-index: 1;
				top: -6px;
				left: calc(50% - 6px);
				width: 4px;
				height: 18px;
				background-color: white;
				border-left: 3px solid rgb(var(--tl-color-brand-grey-darkest));
				transform: rotate(45deg);
			}
		}

		.divider-right {
			flex-grow: 1;

			&::after
			{
				content: "";
				position: absolute;
				z-index: 1;
				top: -8px;
				left: calc(50% + 2px);
				width: 2px;
				height: 18px;
				background-color: white;
				border-right: 3px solid rgb(var(--tl-color-brand-grey-darkest));
				transform: rotate(45deg);
			}
		}
	}
}
