@use "sass:math";

@mixin no-style {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	text-decoration: none;
}

@mixin aspect-ratio-box {
	// aspect ratio
	padding-top: math.div(1, 1) * 100%;
	height: 0;
	overflow: hidden;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	.aspect-ratio-box-inside {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
