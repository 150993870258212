
.vue-tl-loading-component {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: progress;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(var(--tl-color-brand-grey) / 50%);
	background-image: url("@/images/loading.gif");
	background-size: 50px;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 10;

	&.full-screen {
		position: fixed;
		z-index: 999999;
	}

	&.outer-border {
		top: -20px;
		left: -20px;
		right: -20px;
		bottom: -20px;
		border-radius: var(--tl-border-radius);
	}
}
