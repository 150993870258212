html,
& {
	margin: 0;
	padding: 0;
}

ul,
ol {
	padding: 0;
	margin: 0 0 10px 25px;
}