& {
	box-sizing: border-box;

	* {
		box-sizing: inherit;
	}
}

table {
	width: 100%;
}

.content-cols {
	@media screen and (min-width: 800px) {
		display: flex;
		gap: 80px;

		.col {
			flex: 1;
		}

		.col-7 {
			width: 70%;
		}

		.col-3 {
			width: 30%;
		}
	}
}

.container {
	margin-left: 10px;
	margin-right: 10px;

	@media screen and (min-width: 800px) {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
	}
}

main {
	padding-top: 20px;
	padding-bottom: 20px;

	@media screen and (min-width: 800px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}