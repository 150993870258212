

.users-loaded {

	.table-container {
		height: 400px;
		overflow-y: auto;
	}
}

.table-container {

	thead tr th {
		position: sticky;
		top: 0;
	}
}
