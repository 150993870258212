@font-face {
	font-family: 'Atyp Display';
	src: url('@/fonts/AtypDisplay-Medium.woff2') format('woff2'),
		url('@/fonts/AtypDisplay-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Atyp Display';
	src: url('@/fonts/AtypDisplay-Regular.woff2') format('woff2'),
		url('@/fonts/AtypDisplay-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url('@/fonts/Poppins-Bold.woff2') format('woff2'),
		url('@/fonts/Poppins-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url('@/fonts/Poppins-Medium.woff2') format('woff2'),
		url('@/fonts/Poppins-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: Poppins;
	src: url('@/fonts/Poppins-Regular.woff2') format('woff2'),
		url('@/fonts/Poppins-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

