
	.broadcast-modal.vue-tl-modal-component {

		.content-inner {
			padding: 0;
			border-top: 0;
			background-color: transparent;
			width: 900px;
		}
	}
