
@import "@/../node_modules/vue-multiselect/dist/vue-multiselect.css";

.cancel-magic-link-button {

	&:hover {
		text-decoration: underline;
		color: red;
		cursor: pointer;
	}
}

.show-historical-links {
	text-align: center;
	cursor: pointer;
}

.magic-link-user-search {
	width: 250px;
}

.filter-header {
	cursor: pointer;
	padding-right: 18px;
}

.filter-header::after {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid lightgray;
	content:"";
	position:absolute;
	margin-top: 8px;
	margin-left: 4px;
}

.active-filter-header::after {
	border-top: 5px solid gray;
}

.active-filter-header.reverse-filter-header::after {
	border-top: 0;
	border-bottom: 5px solid gray;
}
