
@import "@/scss/animations";

.thought-announcement {
	--filter: 100%;

	background-color: rgb(var(--tl-color-space-default));
	filter: brightness(var(--filter));

	&.thought-announcement-space-specific {
		background-color: rgb(var(--tl-color-space-announcement));
	}

	&:nth-child(2) {
		--filter: 110%;
	}

	&:nth-child(3) {
		--filter: 120%;
	}

	&:nth-child(4) {
		--filter: 130%;
	}

	/* Content is encapsulated in p tags, allow for the p margin-bottom of 10px */
	color: white;
	text-align: center;
	font-size: 16px;
	overflow: hidden;
	transition: max-height 0.3s;

	.inner {
		position: relative;
		padding: 20px 40px 10px 40px;

		&:deep() {

			p {
				max-width: initial;
			}

			p * {
				color: white !important;
				font-size: 16px !important;
			}

			a {
				word-break: break-all;
				color: white !important;

				&:link,
				&:visited,
				&:hover {
					color: white !important;
					border-bottom: 1px dotted white;
					text-decoration: none;
				}

				&:active {
					color: white !important;
					border-bottom: 1px dotted white;
					text-decoration: none;
				}

				&:hover {
					border-bottom: 1px solid white;
				}
			}
		}
	}

	&.hiding {
		max-height: 0;
	}

	.hide-announcement {
		position: absolute;
		top: 50%;
		margin-top: -9px;
		right: 16px;
		color: white;
		font-size: 18px;
		cursor: pointer;
		animation: webpack-pulsate 7s ease-out 4s infinite;
	}
}
