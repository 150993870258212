
	header {
		background-color: rgb(var(--tl-color-brand-blue));

		nav {
			display: flex;
			align-items: center;

			menu {
				margin: 0 0 0 auto;

				ul {
					margin: 0;
					list-style: none;

					li {

						a {
							color: white;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	footer {
		padding: 20px 0;
		background-color: rgb(var(--tl-color-brand-grey));
	}
