
.space-person {
	position: relative;
	border-radius: 4px;
	border: 1px solid #dfdfdf;
	padding: 6px 6px 30px 6px;

	.space-person-details {

		p {
			margin: 0;
		}

		.user-display-name {
			font-weight: bold;
			font-size: 12px;
			line-height: 17px;
			margin-bottom: 2px;
		}

		.user-expertise {
			font-size: 11px;
			line-height: 14px;
		}
	}

	.map-pin {
		position: absolute;
		bottom: 7px;
		right: 7px;

		img {
			width: 15px;
			opacity: 0.7;
		}
	}
}
