& {
	--tl-typography-font-size: 16px;
	--tl-typography-font-size-small: 0.7em;
	--tl-typography-line-height: 1.4em;
	--tl-typography-line-height-h: 1.3em;
	--tl-typography-font-family: 'Poppins', sans-serif;
	--tl-typography-font-family-heading: 'Atyp Display', sans-serif;

	&,
	input, 
	select, 
	option,
	textarea, 
	button {
		font-size: var(--tl-typography-font-size);
		font-family: var(--tl-typography-font-family);
		font-weight: 400;
		line-height: var(--tl-typography-line-height);
		color: rgb(var(--tl-color-font-text));
	}

	
	h1, h2, h3, h4, h5 {
		font-family: var(--tl-typography-font-family-heading);
		letter-spacing: 0.1rem;
		line-height: var(--tl-typography-line-height-h);
	}
	
	a {
		color: rgb(var(--tl-color-font-link));
	
		&:link, &:visited, &:active, &:hover {
			color: rgb(var(--tl-color-font-link));
		}
	}

	.pullout {
		padding: 40px 0 30px 0;
		margin: 0;

		span {
			white-space: nowrap;
		}
	}
}